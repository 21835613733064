<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">详情</div>
      <div class="topRight"></div>
    </div>
    <div class="hearNameSchool">
      <div class="hear">
        <img :src="trends.userHeadImg"/>
      </div>
      <div class="nameSchool">
        <div class="name">{{ trends.userNickName }}</div>
        <div class="school">{{ trends.schoolName }}</div>
      </div>
    </div>
    <div class="trendsContent">
      {{ trends.content }}
    </div>
    <div class="imgBox">
      <img :src="item" alt="" v-for="item in trends.imgs" :key="item"/>
    </div>
    <div class="prompt">该文仅代表坐着本身，平台仅提供信息存储空间服务</div>
    <div class="commentBox">
      <div class="commentContainer">
        <div class="commentNum">
          <div>评论区</div>
          <div>{{ trends.commentTotal }}</div>
        </div>
        <div class="likeNum">{{ trends.likeNum }}赞 | {{ trends.forwardNum }}转发</div>
      </div>
      <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
      <!-- <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          offset="800"
          @load="onLoad"
        > -->
<!--      <div class="commentDiv">-->
<!--        <div class="commentHearTitle">-->
<!--          <div class="commentHear">-->
<!--            &lt;!&ndash; <img :src="item.headImg" alt /> &ndash;&gt;-->
<!--          </div>-->
<!--          <div class="commentTitle">潇洒先生</div>-->
<!--        </div>-->
<!--        <div class="commenContent">-->
<!--          <div class="contentStyle">评论内容</div>-->
<!--          <div class="replyTimeDel">-->
<!--            <div>-->
<!--              回复-->
<!--              <img src="../../image/hf.png" alt />-->
<!--            </div>-->
<!--            <div>2023-12-26-15:18</div>-->
<!--            <div @click="show = true"><img src="../../image/jbIcon.png" alt="" /></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          offset="800"
        >
        <div class="commentDiv" v-for="item in trendsComment" :key="item.id">
          <div class="commentHearTitle">
            <div class="commentHear">
               <img :src="item.headImg" alt />
            </div>
            <div class="commentTitle">{{ item.nickName }}</div>
          </div>
          <div class="commenContent">
            <div class="contentStyle">{{ item.content }}</div>
            <div class="replyTimeDel">
              <div @click="showPopup1(item)">
                回复
                <img src="../../image/hf.png" alt />
              </div>
              <div>{{ item.createTime }}</div>
              <div @click="showPopup(item)"><img src="../../image/jbIcon.png" alt="" /></div>
            </div>
          </div>
        </div>
        </van-list>
      </van-pull-refresh>
      <!-- </van-list> -->
      <!-- </van-pull-refresh> -->
    </div>
    <div class="detailFooter">
      <div class="footerTop">
        <div class="footerLeft">
          <img src="../../image/xpl.png" alt />
          <input
            type="text"
            v-model="comment.content"
            placeholder="写个评论吧~"
          />
        </div>
        <span @click="addEvent()">发送</span>
      </div>
      <div class="footerRight">
        <div class="iconImg"><img src="../../image/xx.png" alt="" />{{ trends.commentNum }}</div>
        <div class="iconImg">
          <img src="../../image/dz.png" alt="" v-if="isLike === 0"  @click="addLike"/>
          <img src="../../image/dz1.png" alt="" v-if="isLike === 1"  @click="delLike"/>
          {{ trends.likeNum }}</div>

        <div class="iconImg">
          <img src="../../image/sc.png" alt="" v-if="isCollect === 0"  @click="addCollect"/>
          <img src="../../image/sc1.png" alt="" v-if="isCollect === 1" @click="delCollect"/>
          {{ trends.collectNum }}
        </div>
        <div class="iconImg" @click="addForward"><img src="../../image/fx.png" alt="" />{{ trends.forwardNum }}</div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="jb" @click="jbEvent()">举报</div>
      <div class="qx" @click="show = false">取消</div>
    </van-popup>
    <van-popup v-model="commentShow" position="bottom">
      <div class="plunBox">
        <textarea autofocus="autofocus"></textarea>
      </div>
    </van-popup>
      <van-popup
      v-model="show1"
      round
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div>
        <div class="commentHearTitle">
          <div class="commentHear">
            <img :src="plObj.headImg" alt />
          </div>
          <div class="commentTitle">{{ plObj.nickName }}</div>
        </div>
        <div class="commenContent">
          <div class="contentStyle">{{ plObj.content }}</div>
          <div class="replyTimeDel">
            <div @click="hfEvent(plObj)">
              回复
              <img src="../../image/hf.png" alt />
            </div>
            <div>{{ plObj.createTime }}</div>
            <div @click="showPopup(plObj)">
              <img src="../../image/jbIcon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="qbHf">全部回复</div>
      <div v-for="item in trendsCommentList" :key="item.id">
        <div class="commentHearTitle">
          <div class="commentHear">
            <img :src="item.headImg" alt />
          </div>
          <div class="commentTitle">{{ item.nickName }}</div>
        </div>
        <div class="commenContent">
          <div class="contentStyle">{{ item.content }}</div>
          <div class="replyTimeDel1">
            <!-- <div @click="hfEvent(item)">
              回复
              <img src="../../image/hf.png" alt />
            </div> -->
            <div>{{ item.createTime }}</div>
            <div @click="showPopup(item)">
              <img src="../../image/jbIcon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>

        <!-- 回复 -->
    <van-popup v-model="hfShow" round position="bottom">
      <div class="footerTop">
        <div class="footerLeft">
          <img src="../../image/xpl.png" alt />
          <input
            type="text"
            v-model="commentForm.content"
            placeholder="写个评论吧~"
          />
        </div>
        <span @click="hfAddEvent()">发送</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Badge } from "vant";
import { Toast } from "vant";
import { Dialog } from "vant";
// import { serveData } from './serve'
import {trendsInfo, getTrendsComment, addTrendsComment, addTrendsStat, getTrendsStat, cancelTrendsStat} from "@/api/trends";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      show: false, //举报弹框
      show1: false,//评论回复
      hfShow:false,
      commentShow: false, //评论弹框
      comment: {
        business: 2,
        infoId: "", //动态id
        parentId: "", //父评论id
        content: "", //评论内容
      },
        commentForm:{
        content:'',
        parentId:'',
        infoId:'',
        business: 2,
      },
      plObj: {},
      trends: {
        id: "",
        title: '',
        content: '',
        imgs: [],
        userHeadImg: '',
        userNickName: '',
        schoolName: '',
        collectNum: 0,
        likeNum: 0,
        commentNum: 0,
        forwardNum: 0,
        commentTotal: 0,
      },
      searchComment: {
        business: 2,
        infoId: '',
        pageNum: 1,
        pageSize: 10,
        parentId:'',
      },
      trendsComment: [],
      trendsCommentList:[],
      isLoading: false,
      loading: false, //上拉加载
      finished: true, //加载与不加载的状态
      isLike: 0,      // 是否点赞（0：否；1： 是）
      isCollect: 0,   // 是否关注（0：否；1： 是）
    };
  },
  mounted() {
    this.trends.id = this.$route.query.id;
    this.searchComment.infoId = this.trends.id;
    this.comment.infoId = this.trends.id;
    this.getTrendsDetail();
    this.getTrendsComment();
    this.getTrendsStat();
    // statPage().then(res=>{
    //   console.log(res,'动态新增浏览记录');
    // })
  },
  methods: {
    getTrendsDetail() {
      trendsInfo(this.trends.id).then(res => {
        this.trends.title = res.data.data.title;
        this.trends.content = res.data.data.content;
        this.trends.imgs = JSON.parse(res.data.data.imgs);
        this.trends.userNickName = res.data.data.userNickName;
        this.trends.userHeadImg = res.data.data.userHeadImg;
        this.trends.commentNum = res.data.data.commentNum;
      })
    },
    // 查询动态评论列表
    getTrendsComment() { 
      getTrendsComment(this.searchComment).then(res => {
        this.trendsComment = res.data.data.items;
        this.trends.commentTotal = res.data.data.total;
        if (res.data.data.items.length === res.data.data.pageSize) {
          this.searchComment.pageNum = res.data.data.pageNum + 1;
        }
      })
    },
    // 下拉加载
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.getTrendsComment();
      }, 1000);
    },
    goBack() {
      this.$router.go(-1);
    },
    // 举报
    jbEvent() {
      if (Cookies.get("token")) {
        Dialog.confirm({
          title: "举报",
          message: "确定要举报当前评论吗？",
        })
          .then(() => {
            // reportAdd(this.report).then((res) => {
            //   console.log(res, "举报");
            //   if (res.data.code === 0) {
            //     Toast("举报成功");
            //     this.show = false;
            //     this.getInfoPageDetail();
            //   }
            // });
            this.show = false;
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 发送评论
    addEvent() {
      addTrendsComment(this.comment).then(res => {
        if (res.data.code === 0) {
          this.$toast("发表成功")
          this.getTrendsComment();
        }
      })
    },
    // 查询动态的点赞、转发、关注数
    getTrendsStat() {
      let params = {};
      params.business = 2;
      params.infoId = this.trends.id;
      getTrendsStat(params).then(res => {
        if (res.data.code === 0) {
          this.trends.likeNum = res.data.data.likeNum;
          this.trends.collectNum = res.data.data.collectNum;
          this.trends.forwardNum = res.data.data.forwardNum;
          this.isLike = res.data.data.isLike;
          this.isCollect = res.data.data.isCollect;
        }
      })
    },
    // 点赞
    addLike() {
      let data ={};
      data.business = 2;
      data.infoId = this.trends.id;
      data.operateType = 0;
      addTrendsStat(data).then(res => {
        if (res.data.code === 0) {
          this.getTrendsStat();
        }
      })
    },
    // 收藏
    addCollect() {
      let data ={};
      data.business = 2;
      data.infoId = this.trends.id;
      data.operateType = 1;
      addTrendsStat(data).then(res => {
        if (res.data.code === 0) {
          this.getTrendsStat();
        }
      })
    },
    // 转发
    addForward() {
      let data ={};
      data.business = 2;
      data.infoId = this.trends.id;
      data.operateType = 2;
      addTrendsStat(data).then(res => {
        if (res.data.code === 0) {
          this.getTrendsStat();
        }
      })
    },
    // 取消点赞
    delLike() {
      let data ={};
      data.business = 2;
      data.infoId = this.trends.id;
      data.operateType = 0;
      cancelTrendsStat(data).then(res => {
        if (res.data.code === 0) {
          this.getTrendsStat();
        }
      })
    },
    // 取消关注
    delCollect() {
      let data ={};
      data.business = 2;
      data.infoId = this.trends.id;
      data.operateType = 1;
      cancelTrendsStat(data).then(res => {
        if (res.data.code === 0) {
          this.getTrendsStat();
        }
      })
    },
     // 回复弹框
    showPopup1(item) {
      console.log(item);
      this.plObj = item;
      this.searchComment.parentId = item.id
      getTrendsComment(this.searchComment).then(res => {
        this.trendsCommentList = res.data.data.items;
      })
      this.show1 = true;
    },
     // 评论回复
    hfEvent(item) {
      this.hfShow = true
      this.commentForm.content = ''
      console.log(item);
      this.commentForm.infoId = item.infoId;
      this.commentForm.parentId = item.id
    },
    hfAddEvent(){
      addTrendsComment(this.commentForm).then(res=>{
        console.log(res,'回复评论');
        if(res.data.code === 0){
          this.$toast("评论成功")
          this.hfShow = false
          getTrendsComment(this.searchComment).then(res => {
          this.trendsCommentList = res.data.data.items;
          })
        }
      })
    },
     // 举报弹框
    showPopup(item) {
      // this.report.commentId = item.id;
      console.log(item);
      this.show = true;
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.topLeft {
  width: 40px;
  height: 40px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 40px;
  height: 40px;
  background-image: url("../../image/xmrx/f.png");
  background-size: 100% 100%;
  margin-right: 32px;
}
.hearNameSchool {
  display: flex;
  margin-left: 32px;
  margin-top: 52px;
}
.nameSchool {
  margin-left: 20px;
}
.hear {
  width: 80px;
  height: 80px;
  //background: red;
  border-radius: 50%;
  overflow: hidden;
}
.hear img{
  height: 100%;
}

.name {
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
}
.school {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.trendsContent {
  width: 686px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 52px;
}
.imgBox {
  display: flex;
  margin-top: 32px;
  margin-left: 16px;
}
.imgBox img {
  width: 218px;
  height: 218px;
  margin-left: 16px;
}
.prompt {
  width: 686px;
  height: 112px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  line-height: 112px;
  border-bottom: 1px solid #f6f8fa;
}
/* 评论 */
.commentBox {
  margin-bottom: 220px;
}
.commentContainer {
  width: 686px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 32px;
  margin-top: 32px;
}
.commentNum {
  height: 64px;
  display: flex;
  font-size: 32px;
  font-weight: 500;
  color: #222222;
  line-height: 64px;
}
.commentNum div:nth-child(2) {
  margin-left: 16px;
}
.likeNum {
  font-size: 24px;
  font-weight: 500;
  color: #999999;
}
.commentHear {
  width: 64px;
  height: 64px;
  margin-left: 32px;
}
.commentHear img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.commentTitle {
  font-size: 28px;
  font-weight: 500;
  color: #222222;
  margin-left: 16px;
}
.commenContent {
  width: 606px;
  margin-left: 112px;
  margin-top: 8px;
}
.contentStyle {
  font-size: 28px;
  font-weight: 400;
  color: #222222;
}
.replyTimeDel ,.replyTimeDel1{
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.replyTimeDel div:nth-child(1) {
  height: 56px;
  border-radius: 806px 806px 806px 806px;
  font-size: 24px;
  font-weight: 400;
  color: #222222;
  line-height: 57px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  background: #f7f8fa;
}
.replyTimeDel div:nth-child(1) img {
  width: 20px;
  height: 20px;
}
.replyTimeDel div:nth-child(2) {
  width: 440px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  margin-left: 32px;
}
.replyTimeDel div:nth-child(3) {
  font-size: 24px;
  color: #999999;
}
.replyTimeDel div:nth-child(3) img {
  width: 24px;
  height: 24px;
}
.replyTimeDel1{
  display:flex;
  justify-content: space-between;
   font-size: 24px;
  font-weight: 400;
  color: #999999;
}
.replyTimeDel1 img{
  width: 24px;
  height: 24px;
}
.detailFooter {
  width: 750px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: fixed;
  bottom: 0px;
}
.footerLeft {
  width: 610px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.footerLeft img {
  width: 28px;
  height: 28px;
  margin-left: 24px;
}
.footerLeft input {
  outline: none;
  width: 500px;
  border: none;
  font-size: 28px;
  background: #f7f8fa;
  margin-left: 8px;
}
.footerRight {
  margin-top: 58px;
  margin-bottom: 26px;
  display: flex;
  justify-content: space-around;
}
.footerRight img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}
.iconImg {
  font-size: 24px;
  font-weight: 400;
  color: #0b1526;
  display: flex;
  align-items: center;
}

.jb {
  width: 750px;
  height: 108px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  line-height: 108px;
  border-bottom: 1px solid #ebedf0;
}
.qx {
  width: 750px;
  height: 108px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 108px;
}
.commentHearTitle {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.commentHear {
  width: 64px;
  height: 64px;

  margin-left: 32px;
}
.commentHear img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.commentTitle {
  font-size: 28px;
  font-weight: 500;
  color: #222222;
  margin-left: 16px;
}
.commenContent {
  width: 606px;
  margin-left: 112px;
  margin-top: 8px;
}
.contentStyle {
  font-size: 28px;
  font-weight: 400;
  color: #222222;
}
.replyTimeDel {
  display: flex;
  align-items: center;
  margin-top: 16px;
  /* margin-bottom: 38px; */
}
.replyTimeDel div:nth-child(1) {
  height: 52px;
  background: #f7f8fa;
  border-radius: 806px 806px 806px 806px;
  font-size: 24px;
  font-weight: 400;
  color: #222222;
  line-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.replyTimeDel div:nth-child(1) img {
  width: 20px;
  height: 20px;
  display: flex;
  /* align-items: center; */
}
.replyTimeDel div:nth-child(2) {
  width: 450px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  margin-left: 32px;
}
.replyTimeDel div:nth-child(3) {
  font-size: 24px;
  color: #999999;
}
.replyTimeDel div:nth-child(3) img {
  width: 24px;
  height: 24px;
}
.qbHf {
  color: rgba(0, 0, 0, 0.9);
  font-size: 32px;
  font-weight: 600;
  margin-left: 32px;
  margin-top: 32px;
}
.footerTop {
  display: flex;
  align-items: center;
  margin-bottom:32px;
  margin-top:32px;
}
.footerLeft {
  width: 610px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.footerLeft img {
  width: 28px;
  height: 28px;
  margin-left: 24px;
}
.footerLeft input {
  outline: none;
  width: 500px;
  border: none;
  font-size: 28px;
  background: #f7f8fa;
  margin-left: 8px;
}
.footerTop span {
  display: inline-block;
  margin-right: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #0957c3;
  margin-left: 28px;
}
</style>
